import TrackingWidget from "components/misc/TrackingWidget";
import Footer from "components/static/Footer";
import Navbar from "components/static/Navbar";
import { useState } from "react";
import { getPostByTrackingId } from "state/handlers/trackHandler";

import "./Track.css";

export default function Track() {
    let [trinder, setTrinder] = useState();
    let [formError, setFormError] = useState();
    let [formState, setFormState] = useState({
        code: "",
        stage: 1,
    });

    function handleFormChange(event) {
        const { name, value, type, checked } = event.target;
        setFormState((prev) => {
            return {
                ...prev,
                [name]: type === "checkbox" ? checked : value,
            };
        });
    }

    async function updateTrinder() {
        let { data, error } = await getPostByTrackingId(formState.code);

        if (error) {
            setFormError(error.content);
            setTrinder(undefined);
        } else if (data) {
            setFormError(undefined);
            setTrinder(data);
        } else {
            setFormError("Internal Server Exception.");
            setTrinder(undefined);
        }
    }

    return (
        <div className="track">
            <Navbar />

            <div className="track-content">
                <span>Track Your Post: Online Facility</span>
                <h1> Where is your post in the queue? </h1>

                <h2> Enter your submission identification number: </h2>
                <div className="tracker">
                    <div className="track-form">
                        <input
                            name="code"
                            type="text"
                            placeholder="correct horse battery staple"
                            onChange={handleFormChange}
                            value={formState.code}
                        />
                        <div className="submitBtn" onClick={updateTrinder}>
                            Check
                        </div>
                    </div>

                    {formError && (
                        <div className="track-error">{formError}</div>
                    )}

                    {trinder && (
                        <TrackingWidget
                            trinder={trinder}
                            refresh={updateTrinder}
                        />
                    )}
                </div>

                <p style={{ marginTop: 12 }}>
                    Estimates provided by this service are approximate and are
                    checked in real-time. Posts may be expedited at the
                    discretion of the moderators.
                </p>

                <p>
                    This facility also allows you to withdraw your submissions
                    from the queue, as well as view the copy as edited by the
                    moderators, if applicable.
                </p>
            </div>

            <Footer />
        </div>
    );
}
