import axios from "axios";
import { config } from "state/config";
import { supabase } from "state/supabase";
import { getIp } from "./ipHandler";
import { dateMinusMinutes } from "./timeHandler";
import { generateCode } from "./trackingCodeHandler";

const writePost = async (post) => {
    let error = undefined;

    let trackingCode = await generateCode();
    let ip = await getIp();

    if (await doesExist(post)) {
        return { error: { message: "This post already exists." } };
    }

    const { data: d1, error: e1 } = await supabase
        .from("trinder_submissions")
        .insert([
            {
                created_by_ip_address: ip.ip,
                tracking_code: trackingCode,
                status: "created",
                content: post,
                approvals: [],
                meta: { ip },
            },
        ]);

    await axios({
        method: "post",
        url: `${config["trinder-web-api"]["base-url"]}/notify/trinder`,
        headers: {
            "Content-Type": "application/json",
        },
        data: { content: post },
    });

    if (e1) {
        error = e1;
    }

    return { data: d1, error };
};

const doesExist = async (post) => {
    // Get the date of one hour ago.
    let lastHour = dateMinusMinutes(new Date(), 60);

    const { data } = await supabase
        .from("trinder_submissions")
        .select("content, created_at")
        .gte("created_at", lastHour.toISOString())
        .eq("content", post);

    return data.length !== 0;
};

export { writePost };
