import axios from "axios";
import { config } from "state/config";
import { supabase } from "state/supabase";
import { getIp } from "./ipHandler";
import { dateMinusMinutes } from "./timeHandler";

const postContact = async (contact, message) => {
    let ip = await getIp();

    if (await doesExist(message)) {
        return { error: { message: "This has already been received." } };
    }

    const { data, error } = await supabase.from("contact_form").insert([
        {
            created_by_ip_address: ip.ip,
            contact,
            message,
            meta: { ip },
        },
    ]);

    if (error) {
        console.log(error);
    }

    await axios({
        method: "post",
        url: `${config["trinder-web-api"]["base-url"]}/notify/contact`,
        headers: {
            "Content-Type": "application/json",
        },
        data: { content: message },
    });

    return { data, error };
};

const doesExist = async (message) => {
    // Get the date of one hour ago.
    let lastHour = dateMinusMinutes(new Date(), 60);

    const { data, error } = await supabase
        .from("contact_form")
        .select("message, created_at")
        .gte("created_at", lastHour.toISOString())
        .eq("message", message);

    if (error) {
        console.log(error);
    }

    if (!error && data == null) {
        return false;
    }

    return data.length !== 0;
};

export { postContact };
