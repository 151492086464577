export default function TrinderPreview({ body, onWithdraw, isWithdrawn }) {
    return (
        <div className="tracking-trinder">
            <span>#Trinder</span>
            <p>{body}</p>
            {!isWithdrawn && (
                <input type="submit" value="Withdraw" onClick={onWithdraw} />
            )}
        </div>
    );
}
