import "./Footer.css";

export default function Footer() {
    return (
        <div className="footer">
            <div className="footer-content">
                <h2>
                    For trinity students{" "}
                    <span className="footer-lighter">by</span> trinity students.
                </h2>
            </div>

            <div className="footer-links">
                <div className="footer-credit">
                    <p> Run with ❤ by the trinder admins. </p>
                </div>

                <div className="footer-page-links">
                    <a href="/rules">Rules&nbsp;of&nbsp;Trinder</a>
                    <a href="/contact">Contact us</a>
                    <a href="/volunteer">Volunteer</a>
                </div>

                <div className="footer-socials">
                    <a href="https://instagram.com/tcdtrinder">
                        <i className="fa-brands fa-instagram"></i>
                    </a>
                    <a href="https://twitter.com/tcdtrinder">
                        <i className="fa-brands fa-twitter"></i>
                    </a>
                </div>
            </div>
        </div>
    );
}
