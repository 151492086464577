import Navbar from "components/static/Navbar";
import Hero from "components/static/index/Hero";
import IndexContent from "components/static/index/IndexContent"
import Footer from "components/static/Footer"
import React from "react";

export default function App() {
  return (
      <div className="App">
        <Navbar/>
        <Hero/>
        <IndexContent/>
        <Footer/>
      </div>
  );
}