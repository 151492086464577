import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter, Route, Routes } from "react-router-dom";

import Index from "views/index/Index";
import Track from "views/index/Track";
import Write from "views/index/Write";

import PageNotFound from "views/common/PageNotFound";
import ComingSoon from "views/common/ComingSoon";
import { Provider } from "react-redux";
import store from "state/store";
import Rules from "views/index/Rules";
import Contact from "views/index/Contact";
import TestPage from "views/index/TestPage";
import { isDebug } from "state/config";
import NoticeBanner from "components/common/NoticeBanner";
import TrinderList from "views/index/TrinderList";

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
    <Provider store={store}>
        <BrowserRouter>
            <Routes>
                <Route path="/" element={<Index />} />
                <Route path="/track" element={<Track />} />
                <Route path="/contact" element={<Contact />} />
                <Route path="/write" element={<Write />} />
                <Route path="/rules" element={<Rules />} />
                <Route path="/all-posts" element={<TrinderList />} />

                <Route path="/archive" element={<ComingSoon />} />
                <Route path="/volunteer" element={<ComingSoon />} />
                {isDebug() && (
                    <Route path="/test-page" element={<TestPage />} />
                )}
                <Route path="*" element={<PageNotFound />} />
            </Routes>
        </BrowserRouter>
    </Provider>
);
