import Footer from "components/static/Footer";
import Navbar from "components/static/Navbar";

import notfound from "assets/not_found.svg";
import "./PageNotFound.css";

export default function PageNotFound() {
  return (
    <div className="not-found">
      <Navbar />
      <div className="not-found-content">
        <h1> 404: Not Found</h1>
        <p> This page was not found on our servers.</p>
        <img src={notfound} alt="Character searching for requested document." />
      </div>
      <Footer />
    </div>
  );
}
