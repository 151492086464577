import axios from "axios";
import { config } from "state/config";
import { supabase } from "state/supabase";
import { dateMinusMinutes } from "./timeHandler";

const approveSubmission = async (tracking_code) => {
    await axios.get(
        `${config["trinder-web-api"]["base-url"]}/submissions/approve/${tracking_code}`
    );
};

const rejectSubmission = async (tracking_code) => {
    await axios.get(
        `${config["trinder-web-api"]["base-url"]}/submissions/reject/${tracking_code}`
    );
};

const getAmountInLastMinutes = async (status, minutes) => {
    let lastTime = dateMinusMinutes(new Date(), minutes);

    const { data } = await supabase
        .from("trinder_submissions")
        .select("content, created_at")
        .gte("created_at", lastTime.toISOString())
        .eq("status", status);

    return data.length;
};

const getPostCountLast24Hrs = async () => {
    let lastTime = dateMinusMinutes(new Date(), 60 * 24);

    const { data } = await supabase
        .from("trinder_submissions")
        .select("content, created_at")
        .gte("created_at", lastTime.toISOString());

    return data.length;
};

const getQueue = async () => {
    const { data } = await supabase
        .from("trinder_submissions")
        .select("status")
        .eq("status", "approved");

    return data;
};

const getModerationQueue = async () => {
    const { data, error } = await supabase
        .from("trinder_submissions")
        .select()
        .eq("status", "created");

    console.log("data, ", data);
    if (error) console.error(error);

    return data;
};

export {
    approveSubmission,
    rejectSubmission,
    getPostCountLast24Hrs,
    getAmountInLastMinutes,
    getQueue,
    getModerationQueue,
};
