import { useState } from "react";
import { withdrawPostByTrackingId } from "state/handlers/trackHandler";
import TrinderPreview from "./TrinderPreview";

export default function TrackingWidget({ trinder, refresh }) {
    let [isWithdrawn, setIsWithdrawn] = useState(
        trinder.status === "withdrawn"
    );

    async function onWithdraw() {
        await withdrawPostByTrackingId(trinder.tracking_code);
        await refresh();
        setIsWithdrawn(true);
    }

    return (
        <div className="tracking-widget">
            <TrinderPreview
                body={trinder.content}
                isWithdrawn={isWithdrawn}
                onWithdraw={onWithdraw}
            />
            <p>
                Soon this facility will estimate how many days/hours it will
                take until this trinder is posted
            </p>

            <div className="tracking-details">
                <h2> Other Details </h2>
                <table>
                    <tbody>
                        <tr>
                            <td>Created:</td>
                            <td>{trinder.created_at.substring(0, 10)} </td>
                        </tr>
                        <tr>
                            <td>Status</td>
                            <td>{trinder.status}</td>
                        </tr>
                        <tr>
                            <td>Code</td>
                            <td>{trinder.tracking_code}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    );
}
