import "./IndexContent.css";
import contentimage from "assets/content-image.svg";

export default function IndexContent() {
    return (
        <div className="index-content">
            <h1>
                Trinder:
                <span className="index-lighter">
                    &nbsp;Online Romantic Realisations&nbsp;
                </span>
                for Trinity Students
            </h1>

            <p>
                We take your confessions, run them through our magic human-based
                moderation system and then post them to our timelines on our
                various social media platforms for the entire campus to see 👀
            </p>

            <img
                src={contentimage}
                alt="Character holding love-heart shaped balloons"
            />
        </div>
    );
}
