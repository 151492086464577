import "./Hero.css";
import heroimage from "assets/hero-image.svg";
import herowave from "assets/hero-wave.svg";

export default function Hero() {
    return (
        <div className="wave-container">
            <div className="hero">
                <div className="hero-text">
                    <span className="hero-title">
                        Fancy a Trinity student? Lack the grace and decorum to
                        say it to their face?
                    </span>
                    <span className="hero-subtitle">
                        We'll post your confessions <em>anonymously</em> !
                    </span>
                    <div className="hero-buttons">
                        <a className="active" href="/write">
                            Write a Trinder
                        </a>
                        <a href="/track">Track a Trinder</a>
                    </div>
                </div>
                <img
                    src={heroimage}
                    alt="Character spreading love on to a phone."
                />
            </div>
            <img src={herowave} alt="" />
        </div>
    );
}
