import dev from "config.dev.json";
import prod from "config.prod.json";
import int from "config.int.json";

let environments = {
    "trinder.love": prod,
    "integration.trinder-web.pages.dev": int,
    localhost: dev,
};

console.log(
    `Detected environment: ${
        environments[window.location.hostname].environment
    }`
);

const config = isDebug() ? dev : prod;

function isDebug() {
    return window.location.hostname !== "trinder.love";
}

export { config, isDebug };
