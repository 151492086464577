import Footer from "components/static/Footer";
import Navbar from "components/static/Navbar";
import "./Rules.css";

export default function Rules() {
    return (
        <div className="rules">
            <Navbar />
            <div className="rules-content">
                <h1> Rules of Trinder</h1>
                <p>
                    Since we started this project we've run Trinder with a
                    rather opaque moderation system. That is, we haven't given
                    any guidelines for the kinds of posts we accept or
                    (secretly) reject. This is now changing. We're moving
                    towards a volunteer-lead moderation team, with established
                    <i> public </i>
                    set of guidelines for what content we are allowing in our
                    social media channels. Here you can find those rules. If
                    your post has been rejected, you should be able to figure
                    out why here.
                </p>

                <p>
                    <strong>
                        If you feel uncomfortable about a post which may relate
                        to you, please contact us for removal.
                    </strong>
                </p>

                <p>
                    We will never give specific reasons, as sometimes our
                    rejection reasons are outside of particular guidelines, as
                    well use a great deal of human discretion, which is our
                    right as a publisher.
                </p>

                <h2> Guiding Principles</h2>
                <p>
                    These are the principles upon which the rest of these
                    guidelines are built upon. Following these is a good first
                    step.
                </p>

                <ul>
                    <li> Don't be an ass. </li>
                    <li>
                        Don't include full names or other directly identifable
                        information.
                    </li>
                    <li>
                        Keep posts related to Trinity, "finding love," life as a
                        student and things surrounding living in Dublin
                    </li>
                    <li>
                        Respect the rules of our publishers (Twitter/Instagram)
                    </li>
                    <li>No poetry, song lyrics or other similar posts.</li>
                    <li>
                        Report posts you feel shouldn't be on our platform by
                        contacting us on the relevant platforms.
                    </li>
                    <li> DM us pictures of your dogs.</li>
                </ul>

                <h2> Instagram / Twitter rules (summarised)</h2>
                <p>
                    As a publisher on other platforms we must respect said
                    platforms content rules. Repetatively breaking these rules
                    lead to the deletion of the original @trindertcd account on
                    Instagram something we're going to try and prevent going
                    forward at the moderation level.
                </p>

                <p>
                    <strong>
                        This is only an abridged version of the official rules.
                        Please see the official text for specifics.
                    </strong>
                </p>

                <ul>
                    <li>
                        Only submit content that you've created or have the
                        right to share.
                    </li>
                    <li>
                        Make submissions approviate for a diverse and modern
                        audience.
                    </li>
                    <li>
                        Do not incite artificial engagement with your
                        submissions when posted. This includes having your
                        friends like and comment.
                    </li>
                    <li>Make sure submissions follow Irish law.</li>
                    <li>Respect other members of the Trinder communuity</li>
                    <li>
                        No depictions of self-injury/harm/suicide, even as a
                        joke.
                    </li>
                    <li>
                        Do not threaten violence against another person or
                        group.
                    </li>
                    <li>
                        Do not make reference to terrorism, domestic or
                        otherwise.
                    </li>
                    <li>
                        Do not submit information which could lead to the
                        "doxing" or leaking of home addresses, phone numbers,
                        social media, etc.
                    </li>
                </ul>
            </div>
            <Footer />
        </div>
    );
}
