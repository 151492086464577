import axios from "axios";

const getIp = async () => {
    try {
        const res = await axios.get(
            "https://web-api.trinder.love/info/ip-address"
        );
        return res.data;
    } catch (e) {
        console.log("browser blocked attempt to grab IP");
        return { ip: -1, reason: "browser blocked attempt to grab IP" };
    }
};

export { getIp };
