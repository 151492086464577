import Footer from "components/static/Footer";
import Navbar from "components/static/Navbar";
import contactimage from "assets/team-meeting.svg";
import "./Contact.css";
import { useState } from "react";
import { supabase } from "state/supabase";
import { getIp } from "state/handlers/ipHandler";
import { postContact } from "state/handlers/contactHandler";

export default function Contact() {
    let [form, setForm] = useState({});
    let [response, setResponse] = useState();

    function change(event) {
        setForm((prev) => {
            return {
                ...prev,
                [event.target.name]: event.target.value,
            };
        });
    }

    async function handleSubmit() {
        let { data, error } = await postContact(form.contact, form.message);

        if (error) {
            console.log(error);
            setResponse(error.message);
            return;
        }

        if (data && data.length >= 1) {
            setResponse("Successfully submitted.");
        }
    }

    return (
        <div className="contact">
            <Navbar />
            <div className="contact-content">
                <h1> Contact us</h1>
                <p>
                    We're always looking for feedback, or for issues to be
                    reported to us as soon as possible. Use this facility to
                    send us a message!
                </p>

                <img
                    src={contactimage}
                    alt="Three characters having a meeting."
                />

                <div className="contact-fields">
                    <label htmlFor="contact">
                        Contact (instagram, email, etc.)
                    </label>
                    <input
                        name="contact"
                        value={form.contact}
                        onChange={change}
                    />
                </div>

                <textarea
                    name="message"
                    value={form.message}
                    onChange={change}
                    cols="120"
                    rows="15"
                    placeholder="Something's broken.. our latest post shouldn't have gone up... Hi! It's up to you."
                />

                <p>
                    If you have not provided contact information for yourself
                    (top field) we will be unable to contact you.
                </p>
                {!response && (
                    <input
                        type="submit"
                        value="Submit"
                        onClick={handleSubmit}
                    />
                )}
                {response && <strong> {response}</strong>}
            </div>
            <Footer />
        </div>
    );
}
