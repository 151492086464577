import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
    approveSubmission,
    getAmountInLastMinutes,
    getPostCountLast24Hrs,
    getQueue,
    rejectSubmission,
    getModerationQueue,
} from "state/handlers/submissionHandler";
import { getRelativeTime } from "state/handlers/timeHandler";
import { supabase } from "state/supabase";
import "./TrinderList.css";

export default function TrinderList() {
    const [trinders, setTrinders] = useState([]);
    const [settings, setSettings] = useState([]);
    const [widgetStats, setWidgetStats] = useState({ moderationQueueSize: 0 });

    let postsPerDay = 24;
    let setting = getSetting("posts");
    console.log(setting);
    let navigate = useNavigate();

    async function approve(code) {
        await approveSubmission(code);
        await getTrinders();
    }

    async function reject(code) {
        await rejectSubmission(code);
        await getTrinders();
    }

    function getSetting(key) {
        for (let setting in settings) {
            if (setting.key === key) {
                return setting;
            }
        }

        return null;
    }

    const trinderElems = trinders.map((trinder) => (
        <tr key={trinder.tracking_code} className={trinder.status + "-bg"}>
            <td>{getRelativeTime(new Date(trinder.created_at), new Date())}</td>
            <td>{trinder.content}</td>
            <td>
                {trinder.status === "created" ? (
                    <>
                        <div
                            className="tl-button tl-button-approve"
                            onClick={() => {
                                approve(trinder.tracking_code);
                            }}
                        >
                            Approve
                        </div>
                        <div
                            className="tl-button tl-button-reject"
                            onClick={() => {
                                reject(trinder.tracking_code);
                            }}
                        >
                            Reject
                        </div>
                    </>
                ) : (
                    <p>No actions available.</p>
                )}
            </td>
        </tr>
    ));

    async function getTrinders() {
        let { data } = await supabase
            .from("trinder_submissions")
            .select("created_at, content, status, tracking_code")
            .order("created_at", { ascending: true });

        setTrinders(data);
    }

    async function getSettings() {
        let { data, error } = await supabase.from("settings").select();

        if (data) console.log(data);
        if (error) console.error(error);
    }

    useEffect(() => {
        async function load() {
            await getTrinders();
            await getSettings();
            setWidgetStats({
                new: await getPostCountLast24Hrs(),
                withdrawn: await getAmountInLastMinutes("withdrawn", 60 * 24),
                rejected: await getAmountInLastMinutes("rejected", 60 * 24),
                posted: await getAmountInLastMinutes("posted", 60 * 24),
                postQueueSize: (await getQueue()).length,
                moderationQueueSize: (await getModerationQueue()).length,
            });
        }

        load();
    }, []);

    return (
        <div className="trinder-list">
            <h1>In the last 24 hours...</h1>
            <div className="list-card-widgets">
                <div className="list-card-widget">
                    <span className="list-card-widget-title">New</span>
                    <p>{widgetStats["new"]}</p>
                </div>
                <div className="list-card-widget">
                    <span className="list-card-widget-title">Withdrawn</span>
                    <p>{widgetStats["withdrawn"]}</p>
                </div>

                <div className="list-card-widget">
                    <span className="list-card-widget-title">Rejected</span>
                    <p>{widgetStats["rejected"]}</p>
                </div>

                <div className="list-card-widget">
                    <span className="list-card-widget-title">Posted</span>
                    <p>{widgetStats["posted"]}</p>
                </div>
            </div>
            <h1> In the future..</h1>
            <div className="list-card-widgets">
                <div className="list-card-widget">
                    <span className="list-card-widget-title">
                        Left&nbsp;queued
                    </span>
                    <p>{widgetStats["postQueueSize"]}</p>
                </div>
                <div className="list-card-widget">
                    <span className="list-card-widget-title">
                        Requires&nbsp;moderation
                    </span>
                    <p>{widgetStats["moderationQueueSize"]}</p>
                </div>
                <div className="list-card-widget">
                    <span className="list-card-widget-title">
                        Time&nbsp;'til&nbsp;Live
                    </span>
                    <p>{String(widgetStats["postQueueSize"] / postsPerDay)}</p>
                </div>
            </div>

            <div className="list-card-controls">
                {/* <div className="tl-button tl-button-reject" onClick={() => {}}>
                    Enable Posting
                </div> */}

                <div
                    className="tl-button tl-button-neutral"
                    onClick={() => navigate("/write")}
                >
                    Write page
                </div>

                <div
                    className="tl-button tl-button-neutral"
                    onClick={() => navigate("/track")}
                >
                    Track page
                </div>
            </div>

            <h1>Trinder Management</h1>
            <table className="color-key">
                <tbody>
                    <tr>
                        <th className="approved-bg">Approved</th>
                        <th className="rejected-bg">Rejected</th>
                    </tr>
                    <tr>
                        <th className="posted-bg">Posted</th>
                        <th className="withdrawn-bg">Withdrawn</th>
                    </tr>
                </tbody>
            </table>
            <table>
                <thead>
                    <tr>
                        <th>Date</th>
                        <th>Content</th>
                        <th>Action</th>
                    </tr>
                </thead>
                <tbody>{trinderElems}</tbody>
            </table>
        </div>
    );
}
