import axios from "axios";
import { supabase } from "state/supabase";
import { config } from "state/config.js";

const getPostByTrackingId = async (tracking_code) => {
    tracking_code = tracking_code.trim();

    const { data, error } = await supabase
        .from("trinder_submissions")
        .select("created_at, content, tracking_code, status")
        .eq("tracking_code", tracking_code);

    if (error || data.length === 0) {
        return { error: { content: "This trinder does not exist." } };
    }

    return { data: data[0] };
};

const withdrawPostByTrackingId = async (tracking_code) => {
    let resp = await axios.get(
        `${config["trinder-web-api"]["base-url"]}/track/withdraw/${tracking_code}`
    );
};

export { getPostByTrackingId, withdrawPostByTrackingId };
