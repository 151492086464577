import axios from "axios";
import { useEffect, useState } from "react";
import { config } from "state/config";

export default function TestPage() {
    let [ip, setIP] = useState();

    async function getIpInfo() {
        let resp = await axios.get(
            `${config["trinder-web-api"]["base-url"]}/info/ip-address`
        );
        setIP(resp);
    }

    useEffect(() => {
        getIpInfo();
    }, []);

    return (
        <div>
            <h1>IP: {JSON.stringify(ip)}</h1>
        </div>
    );
}
