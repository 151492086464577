import "./Navbar.css";
import logo from "assets/trinder-logo.png";
import { Link } from "react-router-dom";

export default function Navbar() {
    return (
        <nav className="index-nav">
            <div className="navbar-logo">
                <Link to="/">
                    <img
                        className="navbar-logo"
                        src={logo}
                        alt="Trinder Logo"
                    />
                </Link>
            </div>

            <div className="navbar-links">
                <Link to="/track" className="navbar-link">
                    Track&nbsp;your&nbsp;post
                </Link>
                <Link to="/contact" className="navbar-link">
                    Feedback!
                </Link>
                <Link to="/write" className="navbar-link navbar-button">
                    Write&nbsp;a&nbsp;post
                </Link>
            </div>
        </nav>
    );
}
