import { useDispatch, useSelector } from "react-redux";
import {
    setBannerType,
    setMessage,
    toggleHasConsented,
    setBannerMessage,
    setCode,
} from "state/slices/writeSlice";

import Footer from "components/static/Footer";
import Navbar from "components/static/Navbar";
import "./Write.css";
import { writePost } from "state/handlers/writeHandler";
import { useState } from "react";

export default function Write() {
    const { message, hasConsented, bannerType, bannerMessage, code } =
        useSelector((state) => state.writeSlice);
    const dispatch = useDispatch();
    let [submitLock, setSubmitLock] = useState(false);

    async function handleSubmit(event) {
        setSubmitLock(true);

        if (!hasConsented) {
            dispatch(setBannerType("form-error"));
            dispatch(
                setBannerMessage(
                    "You must consent to data processing to submit a trinder."
                )
            );

            setTimeout(() => {
                dispatch(setBannerType(""));
                dispatch(setBannerMessage(""));
            }, 7000);

            return;
        } else {
            let { data, error } = await writePost(message);

            if (error) {
                dispatch(setBannerType("form-error"));
                dispatch(setBannerMessage("Error:"));
                dispatch(setCode(error.message));

                setTimeout(() => {
                    dispatch(setBannerType(""));
                    dispatch(setBannerMessage(""));
                }, 7000);
                return;
            }

            dispatch(setBannerType("form-success"));
            dispatch(
                setBannerMessage(
                    "Trinder sucessfully submitted. You may track your trinder through the queue using the following code:"
                )
            );
            dispatch(setCode(data[0]["tracking_code"]));
        }

        setSubmitLock(false);
    }

    return (
        <div className="write">
            <Navbar />
            <div className="write-content">
                <h1>
                    Hey there! Remember the <br />
                    <span>person</span>.
                </h1>

                <p className="write-content-p">
                    We are constantly unindated with unacceptable posts and
                    content which we cannot approve. Please don't let this post
                    be one of those. Remember the person on the receiving end.
                    Don't be an ass, don't use full names and be sure to include
                    dates when referencing a time period.
                </p>

                <p className="write-content-p">
                    Please see the <a href="/rules">Rules of Trinder</a> for a
                    style guide and content guidelines. These rules are persuant
                    to Instagram and Twitter's respective content policies, as
                    well as our own.
                </p>

                <div className="write-form">
                    <textarea
                        value={message}
                        onChange={(event) =>
                            dispatch(setMessage(event.target.value))
                        }
                        cols="120"
                        rows="15"
                        placeholder="Something funny, cheesy or maybe a combination of both?!"
                    />

                    <div className="write-consent">
                        <input
                            type="checkbox"
                            defaultChecked={hasConsented}
                            onChange={() => dispatch(toggleHasConsented())}
                        />
                        <label htmlFor="hasConsented">
                            I consent to this message being broadcast publicly
                            online.
                        </label>
                    </div>

                    {bannerMessage && (
                        <div className={bannerType}>
                            {bannerMessage} {code && <p> {code} </p>}
                        </div>
                    )}

                    {!submitLock ? (
                        <input
                            type="submit"
                            value="Submit"
                            onClick={handleSubmit}
                        />
                    ) : (
                        <p>Processing...</p>
                    )}
                </div>
            </div>
            <Footer />
        </div>
    );
}
