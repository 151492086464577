import { createSlice } from "@reduxjs/toolkit";

export const counterSlice = createSlice({
  name: "writeSlice",
  initialState: {
    message: "",
    hasConsented: false,
    bannerType: "",
    bannerMessage: "",
    code: ""
  },
  reducers: {
      setMessage: (state, action) => {
          state.message = action.payload;
      },

      toggleHasConsented: (state) => {
          state.hasConsented = !state.hasConsented;
      },

      setBannerType: (state, action) => {
        state.bannerType = action.payload;
      },

      setBannerMessage: (state, action) => {
        state.bannerMessage = action.payload;
      },

      setCode: (state, action) => {
        state.code = action.payload;
      }
  }
});

// Action creators are generated for each case reducer function
export const { setMessage, toggleHasConsented, setBannerType, setBannerMessage, setCode } = counterSlice.actions;

export default counterSlice.reducer;
