const { default: axios } = require("axios");

const generateCode = async () => {
    let words = await getWords();
    return `${getRandomElem(words)} ${getRandomElem(words)} ${getRandomElem(
        words
    )} ${getRandomElem(words)}`;
};

const getWords = async () => {
    let resp = await axios.get("/words.txt");
    let words = resp.data.split("\n");
    return words;
};

const getRandomElem = (array) => {
    return array[getRandomInt(0, array.length - 1)];
};

const getRandomInt = (min, max) => {
    min = Math.ceil(min);
    max = Math.floor(max);
    return Math.floor(Math.random() * (max - min + 1)) + min;
};

export { generateCode };
